import { mdiAccountOutline, mdiAccountSwitch, mdiAlphaMBox, mdiCalendarRange, mdiFamilyTree } from '@mdi/js'

export default [
  {
    subheader: 'Distributor Management',
  },
  {
    title: 'Distributor',
    icon: mdiAccountSwitch,
    children: [
      {
        title: 'Distributors',
        to: 'distributor-list',
      },
      {
        title: 'Teams',
        to: 'team-list',
      },
      {
        title: 'Team Messages',
        to: 'team-message-list',
      },
    ],
  },
  {
    title: 'Registrations',
    icon: mdiAccountOutline,
    to: 'distributor-registration-list',
    action: 'distributor_registration:list',
    resource: 'distributor_registration_management',
  },
  {
    title: 'Campaign',
    icon: mdiCalendarRange,
    children: [
      {
        title: 'Campaign List',
        to: 'campaign-list',
      },
    ],
  },

  {
    title: 'Email Template',
    icon: mdiFamilyTree,
    children: [
      {
        title: 'HealthCoin Expiry Reminder',
        to: 'email-template-expiry',
      },
      {
        title: 'HealthCoin Summary',
        to: 'email-template-summary',
      },
    ],
  },

  {
    title: 'Migration Scheme',
    icon: mdiAlphaMBox,
    children: [
      {
        title: 'Migration Scheme List',
        to: 'migration-scheme-list',
      },
    ],
  },
]
