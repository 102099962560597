import { mdiViewList } from '@mdi/js'

export default [
  {
    subheader: 'Doctor Management',
  },
  {
    title: 'Doctor List',
    icon: mdiViewList,
    to: 'doctors',
  },
]
