<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${appSkinVariant}`"
  >
    <transition
      :name="appRouteTransition"
      mode="out-in"
      appear
    >
      <router-view></router-view>
    </transition>

    <notifications-dialog />
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.content }}
    </v-snackbar>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, watch } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useLayout } from '@core/layouts/composable/useLayout'
import { useRouter } from '@core/utils'

// Layouts
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'

// Dynamic vh
import { NotificationsDialog } from '@/components'
import { currentUserStore } from '@/modules/admin/store'
import appStore from '@/modules/app/store'
import store from '@/store'
import useDynamicVh from '@core/utils/useDynamicVh'

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
    NotificationsDialog,
  },
  setup() {
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    useDynamicVh()

    const profile = computed(() => currentUserStore.state.profile)

    watch([profile], async (newVal, oldVal) => {
      if (newVal[0]) {
        store.dispatch('app/getInfo')

        // TODO: remove after modulize refactoration, get data in store
        store.dispatch('product/fetchAllPlanProducts')
        store.dispatch('product/fetchAllPasses')
        store.dispatch('product/fetchAllCoreDiscountSchemes')
      }
    })

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
      snackbar: appStore.state.snackbar,
    }
  },
}
</script>
@/rewardPoint/admin/store
